<template>
  <b-modal v-model="showModal" ref="ModalSelectBranch" centered @hidden="hide" id="modal-branch">
    <template #modal-header>Branch List</template>
    <b-form-group>
      <template #label>
        <b-form-checkbox
          v-model="selectAllBranch"
          @change="toggleAllBranch()"
          :disabled="
            valid == 1
              ? allBranchCheck == allSelectBranchCheck
                ? true
                : false
              : false
          "
          class="my-2"
        >
          All Branches
        </b-form-checkbox>
      </template>
      <b-tabs content-class="mt-3" v-if="!isCounting" ref="b_tabs">
        <b-tab v-for="(item, index) in list" :key="index">
          <template v-slot:title>
            <div style="height: 50px; width: 100px">
              <div class="pt-3">
                <span class="">{{ item.branch_group }}</span>
                <span class=""> ({{ item.selected_count || 0 }}) </span>
              </div>
            </div>
          </template>
          <b-form-group>
            <template>
              <b-form-checkbox
                :ref="`all_${item.branch_group}`"
                v-model="allSelected"
                :key="item.branch_group"
                :value="item.branch_group"
                @input="toggleAll(item.branch_group, index)"
                :tabindex="index"
                :disabled="
                  valid == 1
                    ? item.count == item.branch_true_count
                      ? true
                      : false
                    : false
                "
              >
                {{ item.branch_group }} All Branches
              </b-form-checkbox>
            </template>
            <b-form-checkbox-group v-model="selected" class="mt-3">
              <b-row>
                <b-col cols="6" v-for="(data, i) in item.branch" :key="i">
                  <b-form-checkbox
                    class="mb-2"
                    :value="data.id"
                    :key="data.id"
                    @change="onChange(data.id)"
                    :disabled="
                      valid == 1 ? (data.is_check ? true : false) : false
                    "
                  >
                    {{ data.branch_code }} {{ data.name }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-form-checkbox-group>
          </b-form-group>
        </b-tab>
      </b-tabs>
    </b-form-group>
    <template #modal-footer>
      <b-row>
        <b-col class="text-left">
          <b-button @click.prevent="hide(1)" class="border-btn"
            >Cancel</b-button
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button class="submit-btn" @click.prevent="acceptSelectBranch()"
            >Choose {{ selected.length }} Branch</b-button
          >
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    list: {
      required: true,
      type: Array,
    },
    id: {
      required: true,
      type: String,
    },
    valid: {
      required: false,
      type: Number,
    },
  },
  data() {
    return {
      showModal: false,
      selected: [],
      allSelected: [],
      selectAllBranch: false,
      allBranch: [],
      branch: "",
      countBranch: [],
      allBranchCheck: 0,
      allSelectBranchCheck: 0,
      branchOld: [],
      lockSelected: [],
      isCounting: false,
      branchDelete: [],
      deleteBranch: [],
      tabIndex: 0,
      tabSelected: 0,
    };
  },
  mounted() {
    for (const participating of this.list) {
      participating.selected_count = 0;
      let branchSelectedCount = 0;
      for (const branch of participating.branch) {
        if (branch.is_check) {
          this.selected.push(branch.id);
          this.lockSelected.push(branch.id);
          branchSelectedCount = branchSelectedCount + 1;
        }
      }
    }
    for (let i in this.list) {
      for (let j in this.list[i].branch) {
        this.allBranchCheck =
          this.allBranchCheck + this.list[i].branch_true_count;
        this.allSelectBranchCheck =
          this.allSelectBranchCheck + this.list[i].count;
        if (this.list[i].branch[j].is_check == true) {
          this.branchDelete.push(this.list[i].branch[j].id);
        }
      }
    }
    this.isSelectAll();
  },
  methods: {
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    toggleAll(branchGroup, tabIndex) {
      const focusGroup = this.list.filter((el) => {
        return el.branch_group == branchGroup;
      })[0];
      const checkBoxEl = this.$refs[`all_${branchGroup}`][0];
      const b_tabs = this.$refs.b_tabs;
      if (checkBoxEl) {
        if (checkBoxEl.isChecked) {
          for (const branchToAdd of focusGroup.branch) {
            if (!this.selected.includes(branchToAdd.id)) {
              this.selected.push(branchToAdd.id);
            }
            if (b_tabs && b_tabs.currentTab == tabIndex) {
              if (
                branchToAdd.is_check &&
                this.deleteBranch.includes(branchToAdd.id)
              ) {
                this.deleteBranch.splice(
                  this.deleteBranch.indexOf(branchToAdd.id),
                  1
                );
              }
            }
          }
        } else if (b_tabs) {
          for (const branchToRm of focusGroup.branch) {
            if (this.selected.includes(branchToRm.id)) {
              if (b_tabs.currentTab == tabIndex) {
                if (this.valid === 0) {
                  this.selected.splice(this.selected.indexOf(branchToRm.id), 1);
                  if (
                    branchToRm.is_check &&
                    !this.deleteBranch.includes(branchToRm.id)
                  ) {
                    this.deleteBranch.push(branchToRm.id);
                  }
                } else {
                  if (!branchToRm.is_check) {
                    this.selected.splice(
                      this.selected.indexOf(branchToRm.id),
                      1
                    );
                    if (!this.deleteBranch.includes(branchToRm.id)) {
                      this.deleteBranch.push(branchToRm.id);
                    }
                  }
                }
              }
            }
          }
        }
      }
      this.isSelectAll();
    },
    toggleAllBranch() {
      if (this.selectAllBranch) {
        for (const participating of this.list) {
          this.allSelected.push(participating.branch_group);
          for (const branch of participating.branch) {
            if (!this.selected.includes(branch.id)) {
              this.selected.push(branch.id);
            }
          }
        }
      } else {
        for (const participating of this.list) {
          this.allSelected.push(participating.branch_group);
          for (const branch of participating.branch) {
            if (!branch.is_check) {
              if (this.allSelected.includes(participating.branch_group)) {
                this.allSelected.splice(
                  this.allSelected.indexOf(participating.branch_group),
                  1
                );
              }
              this.selected.splice(this.selected.indexOf(branch.id), 1);
            }
          }
        }
      }
      this.isSelectAll();
    },
    isSelectAll() {
      let isCheckAllBranchCount = 0;
      for (const participating of this.list) {
        let branchSelectedCount = 0;
        for (const branch of participating.branch) {
          if (!participating.selected_count) participating.selected_count = 0;
          if (this.selected.includes(branch.id)) {
            branchSelectedCount = branchSelectedCount + 1;
          }
        }
        participating.selected_count = branchSelectedCount;
        const countBranchEl = this.$refs[`span_${participating.branch_group}`];
        if (countBranchEl) {
          countBranchEl[0].innerHTML = participating.selected_count;
        }
        if (branchSelectedCount == participating.branch.length) {
          if (!this.allSelected.includes(participating.branch_group)) {
            this.allSelected.push(participating.branch_group);
          }
          isCheckAllBranchCount = isCheckAllBranchCount + 1;
        } else if (this.allSelected.includes(participating.branch_group)) {
          this.allSelected.splice(
            this.allSelected.indexOf(participating.branch_group),
            1
          );
        }
      }
      if (isCheckAllBranchCount == this.list.length && this.list.length !== 0) {
        this.selectAllBranch = true;
      } else this.selectAllBranch = false;
      this.isCounting = false;
    },
    onChange(id) {
      this.isCounting = true;
      for (const branchToDeleteId of this.branchDelete) {
        if (
          !this.selected.includes(branchToDeleteId) &&
          !this.deleteBranch.includes(branchToDeleteId)
        ) {
          this.deleteBranch.push(branchToDeleteId);
        } else if (
          this.selected.includes(branchToDeleteId) &&
          this.deleteBranch.includes(branchToDeleteId)
        ) {
          this.deleteBranch.splice(
            this.deleteBranch.indexOf(branchToDeleteId),
            1
          );
        }
      }
      this.isSelectAll();
    },
    acceptSelectBranch() {
      console.log("object", this.selected);
      this.$emit("selectBranch", this.selected);
      this.$emit("deleteBranch", this.deleteBranch);
      this.showModal = false;
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
::v-deep .nav-link {
  padding: 5px 10px;
}

::v-deep .modal-header {
  background-color: var(--primary-color);
  color: #fff;
  font-size: 18px;
}
.ft-weight {
  font-weight: 600;
}
// ::v-deep .nav-tabs .nav-link {
//   min-width: 0;
//   border-bottom: 1px solid #cccccc;
//   border-color: #ccc !important;
//   border-top: none;
//   border-left: none;
//   border-right: none;
// }
// ::v-deep .nav-tabs .nav-link.active,
// .nav-tabs .nav-item.show .nav-link {
//   background-color: transparent;
//   border-left: 1px solid #ccc;
//   border-bottom: none;
//   border-right: 1px solid #ccc;
//   border-top: 1px solid #ccc;
//   border-radius: 3px;
// }
::v-deep .modal-footer {
  justify-content: space-between;
}
::v-deep a {
  color: #707070 !important;
}
::v-deep .nav-tabs {
  border-bottom: 0;
}

::v-deep .modal-dialog {
  @media (min-width: 576px) {
    max-width: 800px;
  }
}
.btn-add-branch {
  background-color: var(--primary-color);
  color: #fff;
  border: 1px solid var(--primary-color);
}
.btn-cancel {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.pointer {
  cursor: pointer;
}
::v-deep #modal-branch .nav {
  overflow-x:auto ;
  overflow-y: hidden ;
  flex-wrap: unset;
}
</style>
